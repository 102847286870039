import { useI18n } from "@/hooks"
import {
	Button,
	//  Link
} from "@/ui"
import Head from "next/head"

const ErrorNode = ({ systemRoute }) => {
	const { status } = systemRoute
	const { t } = useI18n()

	const messages = {
		404: t("Not found"),
		403: t("Access denied"),
		401: t("Unauthorized"),
		500: t("An error occurred on server"),
	}
	let message = t("An error occurred on client")

	const titles = {
		404: t("Not found"),
		403: t("Access denied"),
		401: t("Unauthorized"),
		500: t("An error occurred on server"),
	}
	let title = t("An error occurred on client")

	if (status && messages[status]) {
		message = messages[status]
	}

	if (status && titles[status]) {
		title = titles[status]
	}

	return (
		<div>
			<Head>
				<title>{title}</title>
			</Head>
			<div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
				<main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
					<div className="py-16">
						<div className="text-center">
							<h2 className="text-secondary uppercase tracking-wide text-3xl font-bold sm:text-4xl">
								{status}
							</h2>
							<h1 className="mt-2 mb-8 text-4xl font-bold uppercase tracking-tight sm:text-5xl">
								{message}
							</h1>
							<Button href="/" variant="mre" className="md:w-fit w-fit">
								{t("Nx:Go to home")}
							</Button>
						</div>
					</div>
				</main>
			</div>
		</div>
	)
}
export default ErrorNode

export const config = {
	id: "error_page",
	params: {},
}
